import React from "react"
import { graphql } from "gatsby"
import { GoDiffModified } from 'react-icons/go'
import styles from '../styles/post.module.css'
import { Link } from "gatsby"
import { Disqus, CommentCount } from 'gatsby-plugin-disqus'
import SEO from "../components/seo.js"
import RecipeJsonLd from "../components/recipeStructuredData"
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
  RedditShareButton,
  RedditIcon,
  EmailShareButton,
  EmailIcon
} from 'react-share'


const PostText = (props) => {
  return <p className={styles.postText}>{props.children}</p>
}

const Post = ({ location, pageContext}) => {
  const postData = pageContext.postData
  const categorySlug = postData.recipe_category? postData.recipe_category.name.replace(/ /g, '_').toLowerCase(): null
  const cuisineSlug = postData.recipe_cuisine? postData.recipe_cuisine.name.replace(/ /g, '_').toLowerCase() : null
  const sep = categorySlug && cuisineSlug? '·': ''
  const shareUrl = 'https://www.tastyappetite.net'
  const disqusConfig = {
    url: `${'https://tastyappetite.net'+location.pathname}`,
    identifier: postData.id,
    title: postData.title
  }
  const metaTags = [{
    'name': 'og:image',
    'content': shareUrl + postData.featureImage.childImageSharp.fluid.src
  },{
    'name': 'og:url',
    'content': shareUrl + location.pathname
  }]
  if(postData.youtubeURL){
    metaTags.push({
      'name': 'og:video',
      'content': postData.youtubeURL
    })
  }

  const socialIconsDisplay = (
    <div className={styles.shareButtonWrapper}>
        <FacebookShareButton 
          url={shareUrl+location.pathname} 
          quote={"Checkout this recipe of " + postData.title} 
          hashtag="#tastyappetite"
        >
            <FacebookIcon size={34} round={true} className={styles.socialIcons}/>
        </FacebookShareButton>
        <TwitterShareButton
          url={shareUrl+location.pathname}
          title={postData.title}
          hashtags={['tastyappetite']}
        >
          <TwitterIcon size={34} round={true} className={styles.socialIcons}/>
        </TwitterShareButton>
        <WhatsappShareButton
          url={shareUrl+location.pathname}
          title={postData.title}
        >
          <WhatsappIcon size={34} round={true} className={styles.socialIcons}/>
        </WhatsappShareButton>
        <TelegramShareButton
          url={shareUrl+location.pathname}
          title={postData.title}
        >
          <TelegramIcon size={34} round={true} className={styles.socialIcons}/>
        </TelegramShareButton>
        <RedditShareButton
          url={shareUrl+location.pathname}
          title={postData.title}
        >
          <RedditIcon size={34} round={true} className={styles.socialIcons} bgStyle={{fill: '#FF4301'}}/>
        </RedditShareButton>
        <EmailShareButton
          subject={postData.title + 'Recipe | tastyappetite.net'}
          body={'Checkout the recipe -' + shareUrl+location.pathname}
        >
          <EmailIcon size={34} round={true} className={styles.socialIcons} bgStyle={{fill: '#bfbfbf'}}/>
        </EmailShareButton>
        <div className={styles.printButton} onClick={() => window.print()}>
          Print
        </div>
    </div>  
  )
  const {
    src,
    srcWebp,
    presentationWidth,
    presentationHeight
  } = postData.featureImage.childImageSharp.fluid;
  let presentationHeightMod = presentationHeight
  let presentationWidthMod = presentationWidth
  let layout = "responsive"
  if(presentationHeight > presentationWidth){
      const reduction = 600 / presentationHeight
      presentationHeightMod = presentationHeight * reduction
      presentationWidthMod = presentationWidth * reduction
      layout = "fixed"
  }
  return (
    <div>
      <SEO
        title={postData.title}
        description={postData.description} 
        meta={metaTags}
        isAmp={true}
      />
      {postData.isRecipe? <RecipeJsonLd recipeData={postData} />: ''}
      <div className={styles.headerWrapper}>
        <div className={styles.headerContainer}>
          <h1 className={styles.postHeader}>{ postData.title }</h1>
          <GoDiffModified color={postData.nonVeg? "red": "green"}  size='2em'/>
        </div>
        <div className={styles.postCategory}> 
          {categorySlug? <Link className={styles.categoryButton} to={'category/' + categorySlug + '/'}>{postData.recipe_category.name}</Link> : ''}
          {sep}
          {cuisineSlug? <Link className={sep? styles.cusineButton: 'cusinenomarg'} to={'cuisine/' + cuisineSlug + '/'}>{postData.recipe_cuisine.name}</Link> : ''}
        </div>
        {postData.keywords.map(({name}) => {
            const keywordSlug = name.replace(/ /g, '_').toLowerCase()
            return (
              <Link to={'collection/' + keywordSlug + '/'}>
                <div className={styles.keyword}> {name} </div>
              </Link>
            )
        })}
      </div>
      {socialIconsDisplay}
      {postData.youtubeURL? 
        <amp-youtube 
            data-videoid={postData.youtubeURL.split('/').slice(-1)[0]}
            layout="responsive"
            width="480"
            height="270"
        />:
        // <Img fixed={ postData.featureImage.childImageSharp.fixed }/>
        <div className={presentationHeightMod > presentationWidthMod? styles.featureImageContainerHM : styles.featureImageContainer}>
          <amp-img 
            src={src} 
            width={presentationWidthMod} 
            height={presentationHeightMod} 
            alt={postData.title} 
            layout={layout} 
          />
        </div>
      }
      {
        postData.youtubeURL?
          <div className={styles.printOnlyImage}>
            <amp-img 
                src={src} 
                width={presentationWidthMod} 
                height={presentationHeightMod} 
                alt={postData.title} 
                layout={layout} 
              />
          </div>: ''
      }
      <div className={styles.postBody}>
        <PostText>{ postData.description }</PostText>
        {
          postData.isRecipe?
            <div>
              <div className={styles.recipeMetaContainer}>
                <div className={styles.recipeMeta}>
                  {postData.calorieCount && postData.calorieCount>0? <PostText>Calorie Count - {postData.calorieCount} calories</PostText>: ''}
                  {postData.preparationTimeMinutes && postData.preparationTimeMinutes>0? <PostText>Preparation Time - {postData.preparationTimeMinutes} Minutes</PostText>: ''}
                  {postData.cookingTimeMinutes && postData.cookingTimeMinutes? <PostText>Cooking Time - {postData.cookingTimeMinutes} Minutes</PostText>: ''}
                  {postData.yieldInServings? <PostText>Yield - {postData.yieldInServings} servings</PostText>: ''}
                </div>
              </div>
              <div className={styles.ingredientsContainer}>
                <h2>Ingredients</h2>
                {
                  postData.ingredients.split('\n').map( (ingredient) => {
                    return <p className={styles.postListItem}>{ingredient}</p>
                  })
                }
              </div>
              {postData.youtubeURL?
                <div className={styles.bodyImage}>
                  <amp-img 
                    src={src} 
                    width={presentationWidthMod} 
                    height={presentationHeightMod} 
                    alt={postData.title} 
                    layout={layout} 
                  />
                </div>: ''
              }
              <h2>Instructions</h2>
              {
                postData.instructions.split('\n').filter((i) => (i.trim().length > 0)).map( (instruction, index) => {
                  return (
                    <div className={styles.instruction}>
                      <p className={styles.postListItem + ' ' + styles.instructionNumber}>{index + 1 + '.'}</p>
                      <p className={styles.postListItem}>{instruction}</p>
                    </div>
                  )
                })
              }
            </div>
          : ''}
      </div>
      {socialIconsDisplay}
      <div className={styles.commentSection}>
        <CommentCount config={disqusConfig} placeholder={'...'} />
        <Disqus config={disqusConfig} />
      </div>
    </div>
  )
}

export default Post